<template>
    <div class="h-screen md:mt-0 mt-6 flex items-center justify-center ">


        <div class="w-[1200px]   h-[60vh] relative overflow-hidden mx-3 rounded-md login-bg pb-2">
            <!-- Upper part with the image -->


            <div class=" absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 ">
                <div class="ml-6">
                    <done-svg class="" />
                    <strong class="text-md text-center text-gray-200">  {{ $t("loggedin") }}</strong>
                </div>
                <div class="my-2">

                    <button @click="$router.push('/')"
                        class="bg-[#F4DA2D] my-2 text-[#210B3E] lg:text-md text-sm  py-3 lg:px-9 md:px-0 px-6 w-56   rounded-lg hover:bg-[rgb(231,244,45)]">
                        {{ $t("back_home") }}
                    </button>
                </div>
            </div>


        </div>
    </div>
</template>
  
<script>
import DoneSvg from '@/components/svgs/DoneSvg.vue'

export default {

    components: {
        DoneSvg

    },
    data() {
        return {

            success: false,
            message: ""
        }
    },
    computed: {

    },

    methods: {

    },
    mounted(){
        setTimeout(()=> this.$router.push('/'),3000)
    }

}
</script>
  
<style scoped>
.auth-slider {
    width: 100%;
    height: 335px;
}

@media only screen and (max-width: 960px) {
    .auth-slider {
        width: 100%;
        height: 280px;
    }
}
</style>