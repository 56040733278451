export const authRoutes = [
  {
    path: 'login',
    name: "login",
    component: () => import('@/views/pages/auth/LoginView.vue'),
    meta: {
      layout: 'auth',
      auth: false
    },
  },
  {
    path: 'subscribe',
    name: "subscribe",
    component: () => import('@/views/pages/auth/SubscribeView.vue'),
    meta: {
      layout: 'auth',
      auth: false
    },
  },
  {
    path: 'subscribe/header-inerchment',
    name: "subscribe.headerInerchment",
    component: () => import('@/views/pages/auth/HeaderInerchment.vue'),
    meta: {
      layout: 'auth',
      auth: false
    },
  },
  {
    path: 'terms',
    name: "Terms.Conditions",
    component: () => import('@/views/pages/auth/TermsConditions.vue'),
    meta: {
      layout: 'auth',
      auth: false
    },
  },

  {
    path: 'logout',
    name: "user.logout",
    component: () => import('@/views/pages/auth/LogOut.vue'),
    meta: {
      layout: 'auth',
      auth: true
    },
  },
  {
    path: 'edit/profile',
    name: "edit.profile",
    component: () => import('@/views/pages/auth/EditProfile.vue'),
    meta: {
      layout: 'default',
      auth: true
    },
  },
  {
    path: 'user/profile',
    name: "user.profile",
    component: () => import('@/views/pages/boba/UserProfile.vue'),
    meta: {
      layout: 'default',
      auth: true
    },
  },
  {
    path: 'unsubscribe',
    name: "unsubscribe",
    component: () => import('@/views/pages/auth/CancelSubscription.vue'),
    meta: {
      layout: 'auth',
      auth: true
    },
  },
  {
    path: 'verify',
    name: "user.verify",
    component: () => import('@/views/pages/auth/VerifyOtp.vue'),
    meta: {
      layout: 'auth',
      auth: false
    },
  },
];