import SuccessView from '@/views/pages/auth/SuccessView.vue'
export const bobaRoutes = [
  {
    path: '/',
    name: "home",
    component: () => import('@/views/pages/boba/HomeView.vue'),
    meta: {
      layout: 'default',
      auth:false
    },
  },
  {
    path: '/explore',
    name: "explore",
    component: () => import('@/views/pages/boba/ExploreView.vue'),
    meta: {
      layout: 'default',
      auth:false
    },
  },
  {
    path: '/search/results',
    name: "search.results",
    component: () => import('@/views/pages/boba/SearchResults.vue'),
    meta: {
      layout: 'default',
      auth:false
    },
  },
  {
    path: '/statistics',
    name: "user.statistics",
    component: () => import('@/views/pages/boba/UserStatistics.vue'),
    meta: {
      layout: 'default',
      auth:true
    },
  },
  {
    path: '/show/games/:title',
    name: "games.show",
    component: () => import('@/views/pages/boba/GameDetails.vue'),
    meta: {
      layout: 'default',
      auth:false

    },
  },
  {
    path: '/categories',
    name: "categories",
    component: () => import('@/views/pages/boba/CategoriesView.vue'),
    meta: {
      layout: 'default',
      auth:false
    },
  },
  {
    path: '/categories/details/:title',
    name: "categories.details",
    component: () => import('@/views/pages/boba/CategoryDetails.vue'),
    meta: {
      layout: 'default',
      auth:false
    },
  },
  {
    path: '/success',
    name: "success",
    component: SuccessView,
    meta: {
      layout: 'default',
      auth: false
    },
  },
];