// src/store/category.js

import { defineStore, acceptHMRUpdate } from 'pinia';
import { http, auth } from './https';
import { AUTHTOKEN, IS_SUBED, USER_DATA } from '@/constants';
export const useAuthStore = defineStore('auth', {
    state: () => ({
        isLoggedIn: localStorage.getItem(IS_SUBED) ? localStorage.getItem(IS_SUBED) : false,
        userInfo: {},
        lang: localStorage.getItem('selectedLocale'),
        error: null
    }),
    getters: {
        getIsloggedIn: (state) => state.isLoggedIn,
        getUserData: (state) => state.userInfo,
        getError: (state) => state.error

    },
    actions: {
        /**
         * confirm subscription action
         * @params phone
         * @param pin 
         * 
         * @response json
         */
        confirmSubscription(pin) {
            let user = localStorage.getItem(USER_DATA);
            if (user) {
                user = JSON.parse(user);
            }

            const data = {
                phone: user.phone,
                otp: pin,
            };

            return new Promise((resolve, reject) => {
                http.post('/eth/verify/otp', data)
                    .then((response) => {
                        if (response.data) {
                            this.isLoggedIn = true;
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error);
                    });

            });

        },
        /**
         * Initiates the login process by checking the existence and confirmation status of a user based on the provided phone number.
         *
         * @param {string} phone - The user's phone number.
         *
         * @returns {Promise} A promise that resolves with the response from the backend.
         * @resolve {Object} Response object from the backend.
         *    - {string} phone - The user's phone number.
         *    - {boolean} status - Indicates whether the user's phone number is confirmed (true) or not confirmed (false).
         * @reject {Error} An error object in case of a failed login attempt.
         *
         * @description
         * The login action begins by checking the existence and confirmation status of the user with the specified phone number.
         *
         * - If the user doesn't exist, the backend responds with the phone number and a status of false.
         *   The user is redirected to the verify OTP page for confirmation.
         *
         * - If the user exists and the phone number is confirmed, the backend responds with the phone number and a status of true.
         *   The user is redirected to the home page.
         *
         * The expected response from the backend provides information about the confirmation status of the phone number.
         */

        async login(phone) {
            let user = this.checkUser(phone);
           
            return new Promise((resolve, reject) => {
                http.post('/eth/login', { phone: user.phone, status: user.confirmed })
                    .then((response) => {
                        if (response.data.success) {
                            this.isLoggedIn = true;
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error);
                    });

            });

        },
        async resend() {
            let user = localStorage.getItem(USER_DATA);

            if (user) {
                user = JSON.parse(user);
         
           
            return new Promise((resolve, reject) => {
                http.post('/eth/resend/otp', { phone: user.phone })
                    .then((response) => {
                        
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error);
                    });

            });
        }else{
            console.log("there's no user yet!")
        }

        },

        /**
         * Checks if the user has already confirmed their phone number.
         *
         * @param {string} phone - The user's phone number to check.
         *
         * @returns {Object} User object with phone and confirmation status.
         * @case1 {Object} User object if the provided phone matches the stored phone number.
         * @case2 {Object} User object with a new phone number and confirmation status set to false if the provided phone does not match the stored phone number.
         * @case3 {Object} User object with a new phone number and confirmation status set to false if USER_DATA is not found in local storage.
         *
         * @description
         * This function checks whether the provided phone number matches the stored phone number in local storage.
         *
         * - If the phone numbers match, it returns the existing user object.
         *
         * - If the phone numbers do not match or USER_DATA is not found in local storage, it creates a new user object with the provided phone number and confirmation status set to false.
         *
         * @returns {Object} User object with phone and confirmation status.
         */
        checkUser(phone) {
            let data = localStorage.getItem(USER_DATA);

            if (data) {
                data = JSON.parse(data);
                if (data.phone === phone) {
                    // Phone numbers match

                    return data;
                } else {
                    // Phone numbers do not match

                    return this.setNewUser(phone);
                }
            } else {
                // USER_DATA not found in local storage
                // Create a new user object with the provided phone and confirmation set to false

                return this.setNewUser(phone);
            }

        },
         updateUser(phone) {
            let data = localStorage.getItem(USER_DATA);
        
            if (data) {
                let userData = JSON.parse(data);
                if (userData.phone === phone) {
                    userData.confirmed = true;
                    localStorage.setItem(USER_DATA, JSON.stringify(userData));
                }
            }
        },
        
        /**
         * 
         * @param {*} phone 
         * @returns newUser
         */
        setNewUser(phone) {
            const newUser = {
                phone: phone,
                confirmed: false
            };

            // Store the new user object in local storage
            localStorage.setItem(USER_DATA, JSON.stringify(newUser));

            return newUser;
        },
        /**
         * logout action
         */
        async logOut() {
            this.isLoggedIn = false
            this.clearData();
        },
        /**
         * unsubscribe action 
         * @response json
         */
        async unsubscribe() {
            // return new Promise((resolve, reject) => {
            //     auth.post('/unsubscribe/mondia')
            //         .then((response) => {
            //             resolve(response)
            //         })
            //         .catch((error) => {
            //             reject(error);
            //         });

            // });
            console.log("not YEt")

        },
        /***
         * fetching user info and favorite games 
         */
        async fetchUserInfo() {
            await auth.get(`/eth/user/info/${this.lang}`)
                .then((response) => {
                    this.userInfo = response.data.subscriber
                    if (this.userInfo && this.userInfo.favorite) {
                        const favoriteData = JSON.stringify(this.userInfo.favorite);
                        localStorage.setItem('userFavorites', favoriteData);
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        // The request was made and the server responded with a status code
                        // that falls out of the range of 2xx

                        this.error = error.response.status;


                    } else if (error.request) {
                        // The request was made but no response was received
                        console.log(error.request);
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        console.log('Error', error.message);
                    }
                });

        },
        async updateProfile(data) {
            return new Promise((resolve, reject) => {
                auth.post("/eth/create/user/profile", data)
                    .then((response) => {
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error);
                    });

            });

        },

        async addToFavorite(gameId) {
            return new Promise((resolve, reject) => {
                auth.post(`/eth/games/favorite/${gameId}`)
                    .then((response) => {
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error);
                    });

            });

        },
        /***
         * set local storage data 
         * token and whatever needed 
         */
        setData(response) {

            localStorage.setItem(IS_SUBED, response.data.isSubscribed);


        },
        /**
         * clear local storage when user is logged out or unsubscribed 
         */
        clearData() {
            this.isLoggedIn = false
            localStorage.removeItem(IS_SUBED);
            localStorage.removeItem(AUTHTOKEN);

        }
    }

})


// make sure to pass the right store definition, `useAuth` in this case.
if (import.meta.webpackHot) {
    import.meta.webpackHot.accept(acceptHMRUpdate(useAuthStore, import.meta.webpackHot))
}