// i18n.js
import { createI18n } from 'vue-i18n';

const messages = {
  en_eth: require('./locales/en/en.json'),
  am: require('./locales/eth/eth.json'),
};

const i18n = createI18n({
  locale: 'am', // Default locale
  messages,
});

export default i18n;
