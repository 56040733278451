export const TOKEN = 'token'
export const LOCAL = 'selectedLocale'
export const UPDATE = 'updateAvailable'
export const SHOWTUTORIAL = 'showTutorial'
export const MESSAGE="Subscription Created Successfully"
export const CREATETOKEN='createToken'
export const PHONE = 'phone'
export const AUTHTOKEN='authToken'
export const IS_SUBED='is_Subed'
export const CONFIRMED = 'confirmed'
export const USER_DATA="user_data"
