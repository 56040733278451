// src/store/category.js

import { defineStore } from 'pinia';
import { http } from './https';
export const useHomeStore = defineStore('home', {
    state: () => ({
        categories: {},
        sliders: [],
        lang: localStorage.getItem('selectedLocale'),
        searchGames: [],
        newUpdate: false

    }),
    getters: {
        getCategories: (state) => state.categories,
        getSliders: (state) => state.sliders,
        getSearchGames: (state) => state.searchGames,
        getNewUpdate: (state) => state.newUpdate
    },
    actions: {
        async fetchHomePageData() {
            let locales = ['am', 'en_eth'];
            try {
                const response = await http.get(`/categories/games/${this.lang}`);

                this.categories = response.data.categories
                    .map(category => ({
                        ...category,
                        games: category.games.slice(0, 6)
                    }))
                    .sort((a, b) => a.order - b.order); // Sort categories based on the order property

                // Sort sliders by the order property
                this.sliders = response.data.sliders
                    .sort((a, b) => a.order - b.order) // First, sort the sliders by the order property
                    .filter(slider => {
                        // Then, filter out sliders with excluded countries containing any of the locales
                        if (slider.excluded_countries) {
                            // Split the excluded_countries string into an array of country codes
                            let excludedCountriesArray = slider.excluded_countries.split(',');
                          
                            // Use the some method to check if any of the locales are included in the excludedCountriesArray
                            return !locales.some(locale => excludedCountriesArray.includes(locale));
                        }
                        // If there's no excluded_countries property, keep the slider in the array
                        return true;
                    });
                await this.searchKeywords(response.data.categories);
            } catch (error) {
                console.error(error);
            }
        },
        async fetchSliders() {
            let locales = ['am', 'en_eth'];
            try {
                const response = await http.get(`/sliders`);
                this.sliders = response.data
                .sort((a, b) => a.order - b.order) // First, sort the sliders by the order property
                .filter(slider => {
                    // Then, filter out sliders with excluded countries containing any of the locales
                    if (slider.excluded_countries) {
                        // Split the excluded_countries string into an array of country codes
                        let excludedCountriesArray = slider.excluded_countries.split(',');
                      
                        // Use the some method to check if any of the locales are included in the excludedCountriesArray
                        return !locales.some(locale => excludedCountriesArray.includes(locale));
                    }
                    // If there's no excluded_countries property, keep the slider in the array.
                    return true;
                });
            } catch (error) {
                console.error(error);
            }
        },

        async searchKeywords(categories) {
            this.searchGames = []
            if (this.categories) {
                this.searchGames = categories.map(item => ({
                    id: item.id,
                    category_title: item.category_title,

                }));
            }
        },

        setNewVersionUpdate() {
            this.newUpdate = true
        },
        UpdateNewUpdateValue() {
            this.newUpdate = false;
        }

    },
});


